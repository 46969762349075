(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
'use strict';

// right now we're writing this all as global js functions, but since we're using browserify
// it would be worth considering a refactor to modularize the tools. At the moment it's probably
// overkill for what we're doing, but if this site gets any more complex we may benefit from it.

function toggleSkillLevelDisplay() {
    var checkbox = this;
    var skillId = $(checkbox).data('skill-id');
    var skillLevelSelector = 'select[name="skill_level[%%]"]'.replace('%%', skillId);
    var skillLevelSelectElement = $(skillLevelSelector);

    if ($(checkbox).is(':checked')) {
        skillLevelSelectElement.val(1);
        skillLevelSelectElement.show();
    } else {
        skillLevelSelectElement.val('');
        skillLevelSelectElement.hide();
    }
}

// this is an ultra code smelly way of doing this. we can't just pass in a parameter
// to differentiate the initial run from subsequent runs (jquery snag), so we're just duplicating the
// function. At the moment it doesn't matter much, but if you need to come back and change any of this
// consider refactoring this code.
function initialToggleSkillLevelDisplay() {
    var checkbox = this;
    var skillId = $(checkbox).data('skill-id');
    var skillLevelSelector = 'select[name="skill_level[%%]"]'.replace('%%', skillId);
    var skillLevelSelectElement = $(skillLevelSelector);

    if ($(checkbox).is(':checked')) {
        skillLevelSelectElement.show();
    } else {
        skillLevelSelectElement.val('');
        skillLevelSelectElement.hide();
    }
}

function aNewUploadButtonIsNeeded() {
    var buttonCount = $('.file-uploads-container').children().length;

    // 10 is an arbitrary button max picked at a design meeting on 2016-03-07
    if (buttonCount < 10) {
        var addedMediaCount = $('.file-uploads-container').children().toArray().reduce(function (count, child) {
            return count + !!$(child).find('input').val();
        }, 0);
    }
    return buttonCount === addedMediaCount;
}

$(document).ready(function () {

    $('input[name^="skill"]').each(initialToggleSkillLevelDisplay);

    $(document).on('click', 'input[name^="skill"]', toggleSkillLevelDisplay);

    $(document).on('click', 'form button[type="submit"]', function (e) {
        var errors = false;

        // reset all error messages function

        $('input[data-required]').each(function (index, element) {
            if ($.trim($(element).val()) === '') {
                errors = true;
                $(element).closest('.form-group').addClass('has-error');
                $(element).siblings('.help-block').show();
            }
        });
        if (errors === true) {
            e.preventDefault();
            $(window).trigger('showAlert', {
                type: 'danger',
                message: 'There are required fields missing values. Please review the form.'
            });
            window.scrollTo(0, 0);
        }
    });

    $('.file-uploads-container').on('change', '.fileUploadButton', function () {
        // var buttonCount = $('.file-uploads-container').children().length;

        // // 10 is an arbitrary button max picked at a design meeting on 2016-03-07
        // if(buttonCount < 10){
        //     var addedMediaCount = $('.file-uploads-container').children().toArray().reduce(function (count, child){
        //         return count + !!$(child).find('input').val();
        //     }, 0);
        // }

        // i.e. if they've used all of the buttons, give them another one
        // this is also where we could put a limit on the number of buttons we give them.
        if (aNewUploadButtonIsNeeded() === true) {
            $(this).closest('.upload-row').clone().appendTo('.file-uploads-container');
        }
    });

    $('.file-uploads-container').on('click', '.delete', function () {
        // this all works, but it seems a bit awkward. If you come back to this, consider refactoring
        // the whole add/remove upload rows approach to make it less awkward.
        var newRow = $(this).closest('.upload-row').clone();
        $(this).closest('.upload-row').remove();

        if (aNewUploadButtonIsNeeded() === true) {
            $(newRow).appendTo('.file-uploads-container');
        }
    });
});

},{}]},{},[1]);
